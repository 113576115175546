<template>
  <el-main>
    <el-form ref="form" :model="addForm" :rules="rules" label-width="140px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="1">
          <div>
            <el-form-item label="活动名称：" prop="activity_name">
              <el-input v-model="addForm.activity_name"></el-input>
            </el-form-item>
            <el-form-item label="活动副标题：" prop="subtitle">
              <el-input v-model="addForm.subtitle"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input v-model="addForm.sort"></el-input>
            </el-form-item>
            <el-form-item label="封面图：" prop="cover_picture">
              <ReadyUploadSource @getSource="val => (addForm.cover_picture = val.path)" :path="addForm.cover_picture" @removeThis="() => (addForm.cover_picture = '')"></ReadyUploadSource>
            </el-form-item>
            <el-form-item label="详情banner图片：" prop="banner_picture">
              <ReadyUploadSource
                @getSource="getServiceImg"
                @changeOrder="changeOrder"
                @removeThis="removeImageThis"
                :isMany="true"
                :manyPath="addForm.banner_picture"
                :showStyle="{ width: '175px', height: '100px' }"
              ></ReadyUploadSource>
              <p style="color: #999; font-size: 13px">建议图片尺寸600*400px，大小不超过1M</p>
            </el-form-item>
            <el-form-item label="活动分享图：" prop="share_picture">
              <ReadyUploadSource @getSource="val => (addForm.share_picture = val.path)" :path="addForm.share_picture" :showStyle="{ width: '140px', height: '100px' }" @removeThis="() => (addForm.share_picture = '')"></ReadyUploadSource>
              <p style="color: #999; font-size: 13px">建议图片尺寸500*400px</p>
            </el-form-item>
            <el-form-item label="赛事奖励：">
              <div class="match-reward">
                <div class="item" v-for="(item, index) in addForm.award" :key="index">
                  <div>
                    <el-form-item label="奖励名称" label-width="80px" :prop="'award.' + index + '.name'" :rules="rules.name">
                      <el-input v-model="item.name"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="奖励金额" label-width="80px" :prop="'award.' + index + '.money'" :rules="rules.money">
                      <el-input v-model="item.money"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="报名状态：">
               <el-radio-group v-model="addForm.activity_status">
                  <el-radio :label="-1">未开始</el-radio>
                  <el-radio :label="1">报名中</el-radio>
                  <el-radio :label="2">已结束</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否显示：">
              <el-switch v-model="addForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="赛事简介" name="2">
          <el-form-item label="赛事简介：" prop="introduction" v-if="activeName == 2">
            <RichText width="600" height="400" editorId="introduction" :richTxt="addForm.introduction" @getContent="getContent"></RichText>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="赛事规则" name="3">
          <el-form-item label="赛事规则：" prop="rule" v-if="activeName == 3">
            <RichText width="600" height="400" editorId="rule" :richTxt="addForm.rule" @getContent="getContent"></RichText>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="赛事奖励详情" name="4">
          <el-form-item label="赛事奖励详情：" prop="award_details" v-if="activeName == 4">
            <RichText width="600" height="400" editorId="award_details" :richTxt="addForm.award_details" @getContent="getContent"></RichText>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <Preservation @preservation="submit">
      <router-link to="/extension/activity/activityList" slot="return" v-if="id" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
    RichText,
  },
  data() {
    return {
      activeName: '1',
      id: '',
      addForm: {
        activity_name: '',
        cover_picture: '',
        subtitle: '',
        sort: '',
        banner_picture: [],
        is_show: 1,
        introduction: '',
        rule: '',
        award_details: '',
        share_picture:'',
        activity_status:-1,
        award: [
          { name: '', money: '' },
          { name: '', money: '' },
          { name: '', money: '' },
        ],
      },
      rules: {
        activity_name: [
          {
            required: true,
            message: '请填写活动名称',
            trigger: 'blur',
          },
        ],
        subtitle: [
          {
            required: true,
            message: '请填写类活动副标题',
            trigger: 'blur',
          },
        ],
        sort: [
          {
            required: true,
            message: '请填写排序',
            trigger: 'blur',
          },
        ],
        cover_picture: [
          {
            required: true,
            message: '请添加封面图',
            trigger: 'blur',
          },
        ],
        banner_picture: [
          {
            required: true,
            message: '请添加banner图片',
            trigger: 'blur',
          },
        ],
        introduction: [
          {
            required: true,
            message: '请填写赛事简介',
            trigger: 'blur',
          },
        ],
        rule: [
          {
            required: true,
            message: '请填写赛事规则',
            trigger: 'blur',
          },
        ],
        award_details: [
          {
            required: true,
            message: '请填写赛事奖励详情',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请填写奖励名称',
            trigger: 'blur',
          },
        ],
        money: [
          {
            required: true,
            message: '请填写奖励金额',
            trigger: 'blur',
          },
        ],
        share_picture: [
          {
            required: true,
            message: '请添加活动分享图',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    //获取banner图片
    getServiceImg(val) {
      let arr = [...this.addForm.banner_picture];
      for (let i in val) arr.push(val[i].path);
      this.addForm.banner_picture = arr;
    },
    // 拖动重新赋值数据
    changeOrder(val) {
      this.addForm.banner_picture = val;
    },
    // 删除单张图片
    removeImageThis(index) {
      this.addForm.banner_picture.splice(index, 1);
    },
    getInfo() {
      this.$axios.post(this.$api.sports.activityInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result;
          let addForm = this.addForm;
          info.award = JSON.parse(info.award);
          info.banner_picture = JSON.parse(info.banner_picture);
          addForm.activity_name = info.activity_name;
          addForm.cover_picture = info.cover_picture;
          addForm.subtitle = info.subtitle;
          addForm.sort = info.sort;
          addForm.banner_picture = info.banner_picture;
          addForm.is_show = info.is_show;
          addForm.introduction = info.introduction;
          addForm.rule = info.rule;
          addForm.award_details = info.award_details;
          addForm.award = info.award;
          addForm.share_picture = info.share_picture;
          addForm.activity_status = info.activity_status;
          addForm.id = info.id;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getContent(val, type) {
      console.log(val, type);
      if (type == 'introduction') {
        this.addForm.introduction = val;
      } else if (type == 'rule') {
        this.addForm.rule = val;
      } else {
        this.addForm.award_details = val;
      }
    },
    submit() {
      this.$refs.form.validate((valid, val) => {
        if (valid) {
          let addForm = _.cloneDeep(this.addForm);
          if (!addForm.introduction) return (this.activeName = '2');
          if (!addForm.rule) return (this.activeName = '3');
          if (!addForm.award_details) return (this.activeName = '4');
          this.$axios.post(this.id ? this.$api.sports.editActivity : this.$api.sports.addActivity, addForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.id ? '编辑成功' : '添加成功');
              this.$router.push('/extension/activity/activityList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding-bottom: 50px;
  min-height: 100%;
  .el-form {
    .el-input,
    .el-textarea {
      width: 500px;
    }
    .match-reward {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .el-input {
          width: 200px;
        }
      }
    }
  }
}
</style>